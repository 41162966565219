import { Component, OnInit, Inject } from '@angular/core';
import { MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { Environment } from '../../environments/environment';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(@Inject(MSAL_INSTANCE) private authService: MsalService) { }

  ngOnInit() {
    this.logout();
  }

  logout() { 
    this.authService.logoutRedirect({
      postLogoutRedirectUri: (Environment.production) ? 'https://pr4.dev.bccapp.com' : 'http://localhost:4200'
    });
  }

}