import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';

const HomeRoutes: Routes = [
    //Put paths here
    {
        path: '',
        component: HomeComponent,
        children: [],
      },
]


@NgModule({
    imports: [RouterModule.forChild(HomeRoutes)],
    providers: []
})
export class HomeRoutesModule { }