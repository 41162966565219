import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HomeComponent } from "./home.component";
import { HomeRoutesModule } from "./home.routes";

@NgModule({
    imports: [ 
        CommonModule,
        HomeRoutesModule,
    ],
    providers: [],
    declarations: [ HomeComponent ],
    exports: [ HomeComponent ]
})
export class HomeModule { }