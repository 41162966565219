import { API, AUTHENTICATION } from "./api";

export const commonEnv = {
    hostname : API.DEV,
    REDIRECT_URL_DEV : AUTHENTICATION.REDIRECT_URL.DEV,
    AUTHENTICATION: {
      AUTH_BASE_URL: AUTHENTICATION.AUTH_BASE_URL.LOCAL,
      AUTHORITY: AUTHENTICATION.AUTHORITY.LOCAL,
      CLIENT_ID: AUTHENTICATION.CLIENT_ID.LOCAL,
      REDIRECT_URL: AUTHENTICATION.REDIRECT_URL.LOCAL,
    },
    production: false
  };