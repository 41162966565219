import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import axios from 'axios';
import { of, from, concat, zip, Subscription } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

type CurrentUser = {
  UserName?: "",
  Email?: "",
  FirstName?: "",
  LastName?: "",
  BCUserId?: ""
};

type CurrentInventoryForProduct = {
  sku? : ""
}


@Component({
  selector: 'home-page-component',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  loginDisplay = false;
  currentUser!: CurrentUser;
  currentUserSuccess = false;
  currentInventoryForProduct!: CurrentInventoryForProduct;
  currentInventorySuccess = false;
  accessToken = "";
  accessTokenKey = "1afbc0f2-70e3-42c7-b043-4690c297d70a.edcc74cf-8ec3-4b0c-89c4-d74c6564ae6d-login.windows.net-accesstoken-d7893602-1973-4523-9509-8d88676defe5-edcc74cf-8ec3-4b0c-89c4-d74c6564ae6d-https://boisecascade-dev.accesscontrol.windows.net//postman https://boisecascade-dev.accesscontrol.windows.net//user_impersonation"

  private _subscription: Subscription[] = [];

  constructor(private authService: MsalService, private msalBroadcastService: MsalBroadcastService, private http: HttpClient) { }

   ngOnInit(): void {
      this.getAccessToken();
      this.setLoginDisplay();
  }

  ngOnDestroy(): void {
    if(this._subscription.length > 0){
      this._subscription.forEach(s => s.unsubscribe());
    }
  }
  
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  getAccessToken() {
    if(localStorage.getItem(this.accessTokenKey)) {
      let value = localStorage.getItem(this.accessTokenKey);
      let parsedValue = JSON.parse(value ?? "");
      if(parsedValue.credentialType == "AccessToken") {
          this.accessToken = parsedValue.secret.toString();
        }
    }
  }


  getInventoryForProduct() {
    axios.get("https://6urqzmufod.execute-api.us-west-2.amazonaws.com/newUI/getInventoryForProduct?organizationId=1027&productId=578",
      {
        headers:
        {
          'AccessToken': this.accessToken
      }
    })
    .then(response => {
      this.currentInventoryForProduct = response.data;
      this.currentInventorySuccess = true;
    })
    .catch((error) => {
      console.log('error ' + error);
    });
  }

  getCurrentUser() {
    axios.get("https://pr4.api.dev.bccapp.com/api/v9/user/current",
      {
        headers:
        {
          'Authorization': 'Bearer ' + this.accessToken
        }
    })
    .then(response => {
      // If request is good...
      this.currentUser = response.data.ReturnObject;
      this.currentUserSuccess = true;
    })
    .catch((error) => {
      console.log('error ' + error);
    });
  }
}
