
const HOSTNAME = {
    LOCAL: 'localhost',
    DEV: 'new.dev.bccapp.com',
    INT: 'new.int.bccapp.com',
    STAGE: 'new.stage.bcconnect.com',
    PROD: 'new.prod.bcconnect.com'
  };


export const API = {
    LOCAL: HOSTNAME.LOCAL,
    DEV: HOSTNAME.DEV,
    INT: HOSTNAME.INT,
    STAGE: HOSTNAME.STAGE,
    PROD: HOSTNAME.PROD
  }

export const AUTHENTICATION = {
    AUTH_BASE_URL: {
        LOCAL: 'http://' + HOSTNAME.LOCAL + ':4200',
        DEV: 'https://' + HOSTNAME.DEV,
        INT: 'https://' + HOSTNAME.INT,
        STAGE: 'https://' + HOSTNAME.STAGE,
        PROD: 'https://' + HOSTNAME.PROD
      },
      AUTHORITY: {
        LOCAL: 'https://login.microsoftonline.com/edcc74cf-8ec3-4b0c-89c4-d74c6564ae6d',
        DEV: 'https://login.microsoftonline.com/edcc74cf-8ec3-4b0c-89c4-d74c6564ae6d',
        INT: 'https://login.microsoftonline.com/edcc74cf-8ec3-4b0c-89c4-d74c6564ae6d',
        STAGE:'https://login.microsoftonline.com/edcc74cf-8ec3-4b0c-89c4-d74c6564ae6d',
        PROD: 'https://login.microsoftonline.com/edcc74cf-8ec3-4b0c-89c4-d74c6564ae6d'
      },
      CLIENT_ID: {
        LOCAL: 'd7893602-1973-4523-9509-8d88676defe5',
        DEV: 'd7893602-1973-4523-9509-8d88676defe5',
        INT: 'd7893602-1973-4523-9509-8d88676defe5',
        STAGE: 'd7893602-1973-4523-9509-8d88676defe5',
        PROD: 'd7893602-1973-4523-9509-8d88676defe5'
      },
      REDIRECT_URL: {
        LOCAL: 'http://localhost:4200',
        DEV: 'https://new.dev.bccapp.com',
        INT: 'https://new.dev.bccapp.com',
        STAGE: 'https://new.dev.bccapp.com',
        PROD: 'https://new.dev.bccapp.com'
      },
    }