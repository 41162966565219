import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalInterceptorConfiguration, MsalGuardConfiguration } from '@azure/msal-angular';

import { Environment } from '../../environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: Environment.AUTHENTICATION.CLIENT_ID,
      authority: Environment.AUTHENTICATION.AUTHORITY,
      redirectUri: (Environment.production) ? Environment.REDIRECT_URL_DEV : Environment.AUTHENTICATION.REDIRECT_URL
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE,
    },

  }) 
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read'])
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read'])
  protectedResourceMap.set('https://pr4.api.dev.bccapp.com//api/v7/user/current', ['user_impersonation'])
  protectedResourceMap.set('https://6urqzmufod.execute-api.us-west-2.amazonaws.com/newUI/getInventoryForProduct?organizationId=1027&productId=578',['user_impersonation'])

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['https://boisecascade-dev.accesscontrol.windows.net//user_impersonation']
      },
    loginFailedRoute: '/login-failed'
  };
}